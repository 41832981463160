<template>
  <b-container>
    <transition name="slide">
      <div v-if="isVisible">
        <b-row>
          <b-col class="p-3">
            <div class="title-text">
              Você gostaria de estender a proteção para o seu ciclocomputador?
            </div>
            <div class="title-description">
              (GPS, medidor de potência, acessórios Garmin..)
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="p-3">
            <div class="box-info">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14px"
                  height="14px"
                  viewBox="0 0 24 24"
                  class="info-icon"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <circle cx="12" cy="12" r="9"></circle>
                  <line x1="12" y1="8" x2="12" y2="9"></line>
                  <line x1="12" y1="11" x2="12" y2="17"></line>
                </svg>
              </div>

              Em caso de sinistro, será obrigatório a apresentação da nota fiscal do
              aparelho com data anterior a contratação do seguro
            </div>
          </b-col>
        </b-row>

        <div class="text-center mt-3 op">
          <div class="btn-group" role="group">
            <button
              type="button"
              :class="{
                'btn-checkd mr-2': selected === false,
                'btn-noCheck': selected !== false,
              }"
              @click="selectOption(false)"
            >
              Não
              <svg
                v-if="selected === false"
                width="18"
                height="18"
                class="ml-1 mb-1"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_3749_4704)">
                  <path
                    d="M22.8186 4.43101L8.99963 18.249C8.90672 18.3423 8.79631 18.4163 8.67473 18.4668C8.55315 18.5173 8.42279 18.5433 8.29113 18.5433C8.15948 18.5433 8.02912 18.5173 7.90754 18.4668C7.78596 18.4163 7.67554 18.3423 7.58263 18.249L2.23863 12.9C2.14572 12.8067 2.03531 12.7327 1.91373 12.6822C1.79214 12.6317 1.66179 12.6057 1.53013 12.6057C1.39848 12.6057 1.26812 12.6317 1.14654 12.6822C1.02496 12.7327 0.914542 12.8067 0.821633 12.9C0.728356 12.9929 0.654344 13.1033 0.603842 13.2249C0.55334 13.3465 0.527344 13.4769 0.527344 13.6085C0.527344 13.7402 0.55334 13.8705 0.603842 13.9921C0.654344 14.1137 0.728356 14.2241 0.821633 14.317L6.16763 19.662C6.73158 20.2249 7.49583 20.5411 8.29263 20.5411C9.08944 20.5411 9.85369 20.2249 10.4176 19.662L24.2356 5.84701C24.3288 5.75412 24.4026 5.64377 24.4531 5.52228C24.5035 5.40079 24.5294 5.27054 24.5294 5.13901C24.5294 5.00747 24.5035 4.87723 24.4531 4.75574C24.4026 4.63425 24.3288 4.5239 24.2356 4.43101C24.1427 4.33773 24.0323 4.26372 23.9107 4.21322C23.7891 4.16272 23.6588 4.13672 23.5271 4.13672C23.3955 4.13672 23.2651 4.16272 23.1435 4.21322C23.022 4.26372 22.9115 4.33773 22.8186 4.43101Z"
                    fill="#ffffff"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3749_4704">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="translate(0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </button>
            <button
              type="button"
              :class="{
                'btn-checkd ml-2': selected === true,
                'btn-noCheck': selected !== true,
              }"
              @click="selectOption(true)"
            >
              Sim
              <svg
                v-if="selected === true"
                width="18"
                height="18"
                class="ml-1 mb-1"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_3749_4704)">
                  <path
                    d="M22.8186 4.43101L8.99963 18.249C8.90672 18.3423 8.79631 18.4163 8.67473 18.4668C8.55315 18.5173 8.42279 18.5433 8.29113 18.5433C8.15948 18.5433 8.02912 18.5173 7.90754 18.4668C7.78596 18.4163 7.67554 18.3423 7.58263 18.249L2.23863 12.9C2.14572 12.8067 2.03531 12.7327 1.91373 12.6822C1.79214 12.6317 1.66179 12.6057 1.53013 12.6057C1.39848 12.6057 1.26812 12.6317 1.14654 12.6822C1.02496 12.7327 0.914542 12.8067 0.821633 12.9C0.728356 12.9929 0.654344 13.1033 0.603842 13.2249C0.55334 13.3465 0.527344 13.4769 0.527344 13.6085C0.527344 13.7402 0.55334 13.8705 0.603842 13.9921C0.654344 14.1137 0.728356 14.2241 0.821633 14.317L6.16763 19.662C6.73158 20.2249 7.49583 20.5411 8.29263 20.5411C9.08944 20.5411 9.85369 20.2249 10.4176 19.662L24.2356 5.84701C24.3288 5.75412 24.4026 5.64377 24.4531 5.52228C24.5035 5.40079 24.5294 5.27054 24.5294 5.13901C24.5294 5.00747 24.5035 4.87723 24.4531 4.75574C24.4026 4.63425 24.3288 4.5239 24.2356 4.43101C24.1427 4.33773 24.0323 4.26372 23.9107 4.21322C23.7891 4.16272 23.6588 4.13672 23.5271 4.13672C23.3955 4.13672 23.2651 4.16272 23.1435 4.21322C23.022 4.26372 22.9115 4.33773 22.8186 4.43101Z"
                    fill="#ffffff"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3749_4704">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="translate(0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </button>
          </div>
        </div>
      </div>
    </transition>

    <button id="footerButtonBack" class="footer-button-back" @click="previousStep">
      Voltar
    </button>
    <button id="footerButton" class="footer-button" @click="handleClick" :disabled="loading">Avançar</button>
  </b-container>
</template>
<script>
import * as D from "@/duck";
export default {
  props: {
    clickHandler: {
      type: Function,
      required: true,
    },
    previousStep: {
      type: Function,
      required: true,
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {},
  data: function () {
    return {
      brands: D.constants.BRANDS,
      selected: true,
      isVisible: false,
      fields: {
        option: "",
      },
    };
  },
  methods: {
    selectOption(option) {
      this.selected = option;
      this.fields.option = option;
      this.emitAcessoriesData();
    },
    handleClick() {
      this.emitAcessoriesData();
      this.clickHandler();
    },
    emitAcessoriesData() {
      this.$emit("acessoriesData", this.fields);
    },
    initializeFields() {
      if (this.data) {
        this.selected = this.data.option || false;
        this.fields.option = this.data.option || false;
      }
    },
  },
  mounted() {
    this.initializeFields();
    this.isVisible = true;
  },
};
</script>
<style scoped>
.col,
.col-2,
.col-6 {
  padding: 0 10px;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease;
}
.slide-enter, .slide-leave-to /* Início e fim da animação */ {
  transform: translateX(-100%);
}
.title-text {
  font-size: 23px;
  line-height: 24px;
  font-weight: bold;
  color: #5db247;
}

.footer-button {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 60px;
  background-color: #4fa935;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  z-index: 9999;
}

.footer-button:disabled {
  background-color: #b1cead;
  cursor: default;
  z-index: 9999;
}

.footer-button-back {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 30%;
  height: 60px;
  background-color: #414040;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  z-index: 9999;
}
.box-info {
  display: flex;
  align-items: center;
  background-color: #f3f3f3; /* Fundo cinza claro */
  border-radius: 4px; /* Borda arredondada */
  font-size: 12px;
  color: #919baf;
  padding: 10px; /* Espaçamento interno */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra sutil */
  margin: 10px;
}

.info-icon {
  width: 24px; /* Largura do ícone */
  height: 24px; /* Altura do ícone */
  margin-right: 10px; /* Espaço entre o ícone e o texto */
}

.title-link {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: #5db247;
  text-decoration: underline;
}

.btn-checkd {
  background-color: #5db247;
  color: #fafafa;
  border: solid 1px #5db247;
  border-radius: 10px;
  padding: 8px;
  width: 80px;
}

.btn-noCheck {
  background-color: #fafafa;
  color: #000000;
  border: solid 1px #dadada;
  border-radius: 10px;
  padding: 8px;
  width: 80px;
}
.title-description {
  font-size: 16px;
  margin-top: 10px;
  line-height: 24px;
  color: #919ab0;
}

.footer-button {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 70%;
  height: 60px;
  background-color: #4fa935;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  z-index: 9999;
}
</style>
